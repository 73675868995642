.app-repo-list-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.app-repo-list-header .refresh-all-button {
  display: inline;
  margin: 0 0 0 0.6rem;
}

.page-content {
  margin: 0 1.2rem;
  /*
    Hide the global package repository text in the package repository section.
    These rules are a little weak, but we don't have a better way to target the
    html elements in the component without altering the tsx source code.
  */
}
.page-content > *:not(table) {
  display: none;
}
