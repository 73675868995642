// Copyright 2020-2022 the Kubeapps contributors.
// SPDX-License-Identifier: Apache-2.0

.button {
  // Override default margin for Clarity
  margin-right: 0 !important;

  & + .button {
    margin-left: 0.5rem;
  }
}
