.drawer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

.kubeapps-menu {
  display: flex;
  align-items: center;
  opacity: 0.75;
}
.kubeapps-menu:hover {
  background-color: #00468a;
  opacity: 1;
}
.kubeapps-menu.open {
  background-color: #00468a;
  opacity: 1;
}
.kubeapps-menu button {
  height: 100%;
  padding: 0 1.5rem;
}

.drawer-backdrop {
  position: absolute;
  z-index: 1015;
  top: 3rem;
  right: 0;
  bottom: 0;
  left: 0;
  height: calc(100vh - 3rem);
  background-color: var(--cds-global-color-gray-900, #313131);
  opacity: 0.85;
}

.dropdown-configuration-menu {
  position: absolute;
  z-index: 1017;
  right: 0;
  display: flex;
  height: calc(100vh - 3rem);
  flex-direction: column;
  justify-content: space-between;
}

.dropdown-menu {
  margin-top: 0 !important;
}

.dropdown-menu-label {
  color: var(--cds-global-color-gray-50, #fafafa);
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.02em;
  opacity: 0.8;
  text-transform: uppercase;
}

.dropdown-menu-link:hover {
  text-decoration: none;
}

div .dropdown-menu .dropdown-divider {
  border-bottom-color: var(--cds-global-color-gray-50, #fafafa);
  opacity: 0.2;
}

.dropdown-menu-item {
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
  margin-left: 1rem;
  color: var(--cds-global-color-gray-50, #fafafa);
  font-weight: 500;
  opacity: 0.65;
}
.dropdown-menu-item img {
  max-width: 20px;
  margin-right: 0.5rem;
}
.dropdown-menu-item cds-icon {
  margin-right: 0.5rem;
}
.dropdown-menu-item:hover {
  opacity: 1;
  transform: translateY(-0.1rem);
  transition: border 0.2s ease, transform 0.2s;
}

.logout-button cds-button {
  display: grid;
  width: 100%;
  --color: var(--cds-global-color-gray-50, #fff);
  --border-color: var(--cds-global-color-gray-50, #fff);
}
.logout-button cds-button:hover {
  --color: var(--cds-global-color-gray-50, #009ffd);
  --border-color: var(--cds-global-color-gray-50, #009ffd);
}

.dropdown-menu-subtext {
  margin-top: 2rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  font-size: smaller;
  text-align: end;
  display: none !important;
}
.dropdown-menu-subtext a:link {
  color: var(--cds-global-color-blue-400, #2ec0ff);
}
.dropdown-menu-subtext a:visited {
  color: var(--cds-global-color-blue-400, #2ec0ff);
}

.transition-drawer-enter {
  opacity: 0;
  transform: translateX(15rem);
}

.transition-drawer-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.transition-drawer-exit {
  opacity: 1;
}

.transition-drawer-exit-active {
  opacity: 0;
  transform: translateX(15rem);
  transition: opacity 300ms, transform 300ms;
}

.toggle-label-text {
  color: var(--cds-global-color-gray-50, #fafafa);
}

.dropdown-theme-toggle {
  margin-top: 0.5em;
}
