.login {
  background-color: #031527 !important;
}
.login-wrapper {
  width: calc(100vw + 0.6rem);
  margin-left: -0.6rem;
  background-color: #031527 !important;
  background-image: url("../../img/bg.jpg") !important;
}
.login-wrapper .login {
  min-height: calc(100vh - 3rem);
}
.login-wrapper .login .login-group {
  padding: 0;
}
.login-wrapper .login .error.active {
  font-size: small;
}
.login-submit-button {
  display: grid;
  padding: 1rem 0 0 0;
}
.login-moreinfo {
  display: none;
  justify-content: end;
  margin-top: 0.5rem;
}
.login-moreinfo svg {
  margin-top: -0.1rem;
}
.login .title h3 span,
.login .title .welcome,
.login .title #login-desc,
.login .title .clr-control-label,
.login .title .clr-input-wrapper input {
  color: #fff;
}
.login .title #login-desc {
  font-size: 20px;
  margin-bottom: 20px;
  line-height: 1.25;
}
.login .title h3 {
  font-size: 31px;
  line-height: 1.5;
}
.login .title h3 span {
  font-size: 48px;
  line-height: 1.125;
  display: block;
  margin-top: 5px;
}

#login-submit-button {
  display: contents;
}

div .login-wrapper .login {
  min-height: 100%;
}

.spark-branding {
  margin-bottom: 80px;
  width: 220px;
}
.spark-branding img {
  max-width: 100%;
}
