// Copyright 2020-2022 the Kubeapps contributors.
// SPDX-License-Identifier: Apache-2.0

.alert {
  &:not(.alert-app-level) {
    .alert-action {
      height: auto;
      padding: 0;
      border: 0;
      margin: 0;
      background: transparent;
      font-size: 13px;
      font-weight: 400;
      letter-spacing: normal;
      line-height: 1.15;
      text-transform: none;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
