// Copyright 2020-2022 the Kubeapps contributors.
// SPDX-License-Identifier: Apache-2.0

.tabs {
  max-width: 100%;
  margin-bottom: 1em;
  overflow-x: auto;
  overflow-y: hidden;

  .tab-button {
    &:focus {
      outline: none;
    }
  }
}
