// Copyright 2020-2022 the Kubeapps contributors.
// SPDX-License-Identifier: Apache-2.0

.spinner {
  &-container {
    &.spinner-center {
      display: block;
      text-align: center;
    }
  }

  &.spinner-inline {
    margin-right: 6px;
  }
}
