// Copyright 2020-2022 the Kubeapps contributors.
// SPDX-License-Identifier: Apache-2.0

.card-header {
  display: block;
  height: auto;
  background-color: inherit;

  &.no-border {
    border-bottom: 0;
  }
}
